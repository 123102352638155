import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages

const Login = React.lazy(() => import('./views/pages/login/Login'))
const BuscarPaciente = React.lazy(() => import('./views/pages/login/BuscarPaciente'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Landing = React.lazy(() => import('./views/pages/landing/Landing'))
const AgendarCita = React.lazy(() => import('./views/pages/landing/AgendarCita'))

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      // <HashRouter>

      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Login Page" element={<Login />} />
            <Route exact path="/BuscarPaciente" name="Buscar" element={<BuscarPaciente />} />

            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/register/:email" name="Consultar usuario" element={<Register />} />

            <Route exact path="/landing" name="Landing Page" element={<Landing />} />
            <Route
              exact
              path="/agendarcita/:especialidad_cod"
              name="Agendar cita"
              element={<AgendarCita />}
            />

            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>

      // </HashRouter>
    )
  }
}

export default App
